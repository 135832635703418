<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ new Date(item.created_at).toLocaleString() }}
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.amount">{{ item.amount.toLocaleString() }} F</span>
        <span v-if="!item.amount">0 F</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.type === 'CASH'">Espèce</span>
        <span v-if="item.type === 'BANK_CHECK'">Chèque</span>
        <span v-if="item.type === 'MOBILE_MONEY'">Mobile money</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="!item.receive_bank">Transfère</span>
        <span v-if="item.receive_bank">Remise</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.sender">{{ item.sender.agency.label }}</span>
        <span v-if="item.receive_bank">{{ item.receive_bank.bankName }}</span>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.receiver">{{ item.receiver.person.fullname }}</span>
        <span v-if="item.receive_bank">-</span>
      </div>

      <div class="w-1/6 ml-4">
          {{ item.person.fullname }}
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
    amount: function () {
      let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
