<template>
  <div class="p-10 pr-16 all">
    <transfereOption :activation="activeOption" v-if="activeOption" @oga="retourOption"/>
    <success v-if="activeSuccess" :message="message" :activation="activeSuccess"  @oga="activeSuccess = false"/>
    <recap :activation="activeRecap" v-if="activeRecap" @oga="retourRecap"/>
    <div>
      <div
        class="flex"
      >
        <div class="w-3/5 h-20 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-4/5">
            Transferts et remises
          </div>
        </div>

        <div class="w-3/5">
          <div class="flex justify-end w-full">
            <div class="w-1/4 ml-4">
              <bouton
                  :icon="icons.calendar"
                  iconHeight="17"
                  iconWidth="17"
                  height="54px"
                  label="Période"
                  color="black"
                  size="14px"
                  radius="5px"
                  class="w-full"
                  background="white"
                  border="1px solid #DCDCE4"
                  @info="activePeriode =! activePeriode"
              />
            </div>

            <div class="w-1/4 ml-4">
              <multiselect
                  :icon="icons.filter"
                  height="54px"
                  background="white"
                  :icon-width="25"
                  value-t="Type"
                  :option="optionType"
                  @info="retourType"
              />
            </div>

            <div class="w-2/4 ml-4">
              <multiselect
                  :icon="icons.filter"
                  height="54px"
                  background="white"
                  :icon-width="25"
                  value-t="Moyen d'opération"
                  :option="optionMoyen"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end items-center text-c16 text-90 w-full mt-6">
        <div class="flex justify-end items-center w-2/4" v-if="activePeriode">
          <div>Du</div>
          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>
          <div class="ml-6">Au</div>
          <datepicker height="51px" class="w-1/3 ml-4" placeholder=""/>
        </div>

        <bouton label="Effectuer une opération" class="w-1/4 ml-6" @info="activeOption = true"/>
      </div>

      <div class="mt-8 pl-4">
        <global :typeOption="type"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import search from '../../assets/icons/search.svg'
import calendar from '../../assets/icons/calendar.svg'
import filter from '../../assets/icons/filter.svg'
import multiselect from '../../component/helper/form/multiselect'
import datepicker from '../../component/helper/form/datePicker'
import global from '../../component/cdevs/historique/globalData'
import transfereOption from '../../component/popup/transfereOption'

export default {
  name: "Index",

  components: {
    bouton,
    multiselect,
    global,
    datepicker,
    transfereOption
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activePeriode: false,
      optionType: ['Transfère', 'Remise'],
      optionMoyen: ['Mobile money', 'Espèce', 'Chèque'],
      activeOption: false,
      type: null
    }
  },

  created () {
  },

  methods: {
    retour () {
      window.history.back()
    },

    goto (index) {
      this.$router.push(index)
    },

    retourOption(answer) {
      this.activeOption = answer
    },

    retourType(answer) {
      if (answer !== 'Type') {
        this.type = answer
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
