<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 w-4/5"
            >
              Transfert de fonds
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 pb-10 bg-white p-6 round">

            <div class="text-c12 mt-4 text-left bloc p-6 flex items-center" @click="goto('/mobileTransfere')">
              <div class="text-43 text-c15 w-full">Transfert de caisse Mobile money</div>
              <icon
                  :data="icons.arrow"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
              />
            </div>

            <div class="text-c12 mt-4 text-left bloc p-6 flex items-center" @click="goto('/transfere')">
              <div class="text-43 text-c15 w-full">Transfert de caisse Recettes</div>
              <icon
                  :data="icons.arrow"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
              />
            </div>

            <div class="text-c12 mt-4 text-left bloc p-6 flex items-center" @click="goto('/remise')">
              <div class="text-43 text-c15 w-full">Remise en banque</div>
              <icon
                  :data="icons.arrow"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
              />
            </div>

          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/right.svg'

export default {
  name: 'Success',
  components: {
    PopupBase,
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        fermer,
        arrow,
      },
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    goto (index) {
      this.$router.push(index)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }
    .diviser {
      border-bottom: 1px dashed #606060;
    }

    .round {
      border-radius: 0px 0px 12px 12px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .bloc{
      height: 56px;
      background: rgba(245, 245, 245, 0.5);
      border: 0.406544px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4.06544px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }

      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
